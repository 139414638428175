<template>
  <div>
    <jzsy-header title="人员管理">
      <div class="right-nav-btn" slot="right">
        <nav-drop-menu :menuItems="rightMenu"></nav-drop-menu>
      </div>
    </jzsy-header>
    <div class="wrapper">
      <md-scroll-view
        ref="scrollView"
        class="scroll-view"
        :scrolling-x="false"
        immediate-check-end-reaching
        auto-reflow
        @end-reached="$_onEndReached"
      >
        <md-field>
          <md-cell-item
            addon="操作"
            arrow
            @click="itemAction(item.staffId, item.isAdmin)"
            v-for="item in datas"
            :key="item.staffId"
          >
            <div class="staff-info">
              <div class="staff-name">{{ item.name }}</div>
              <div class="detail">手机号：{{ item.mobile }}</div>
              <div class="detail">推荐码：{{ item.referralCode }}</div>
            </div>
          </md-cell-item>
        </md-field>
        <md-scroll-view-more slot="more" :is-finished="isFinished" />
      </md-scroll-view>
    </div>
  </div>
</template>

<script>
import {
  ScrollView,
  ScrollViewRefresh,
  ScrollViewMore,
  CellItem,
} from "mand-mobile";
import AdminApi from "@/api/admin";
import NavDropMenu from "@/components/NavDropMenu.vue";
import { Toast } from "mand-mobile";

export default {
  components: {
    "nav-drop-menu": NavDropMenu,
    [ScrollView.name]: ScrollView,
    [ScrollViewMore.name]: ScrollViewMore,
    [ScrollViewRefresh.name]: ScrollViewRefresh,
    [CellItem.name]: CellItem,
  },
  data() {
    return {
      datas: [],
      isFinished: false,
      rightMenu: [
        {
          title: "添加新员工",
          icon: "add-user-black",
          isSvg: true,
          iconSize: "0.42rem",
          clickEvent: this.addStaff,
        },
        {
          title: "已停用账号",
          icon: "quited-staff",
          isSvg: true,
          iconSize: "0.42rem",
          clickEvent: this.quitedStaff,
        },
      ],
    };
  },
  methods: {
    addStaff() {
      this.$router.push("/staff/add");
    },
    quitedStaff() {
      this.$router.push("/staff/list-frozen");
    },
    itemAction(staffId, isAdmin) {
      this.$actionsheet.create({
        value: true,
        options: [
          {
            label: "停用账号",
            value: staffId,
          },
          {
            label: "重置密码",
            value: staffId,
          },
          {
            label: "修改信息",
            value: staffId,
          },
        ],
        invalidIndex: isAdmin ? 0 : -1,
        cancelText: "取消",
        onSelected: this.$_selected,
      });
    },
    $_selected(item) {
      if ("重置密码" === item.label) {
        AdminApi.resetPassword(item.value).then(() => {
          this.$dialog.alert({ content: "密码已重置为：123456" });
        });
      } else if ("停用账号" === item.label) {
        AdminApi.staffQuit(item.value).then(() => {
          this.datas = this.datas.filter((d) => d.staffId != item.value);
          Toast.succeed("操作成功");
        });
      } else if ("修改信息" === item.label) {
        this.$router.push({
          name: "editStaff",
          params: { staffId: item.value },
        });
      }
    },
    $_onEndReached() {
      if (this.isFinished) {
        return;
      }
      this.loadData();
    },
    loadData() {
      let lastId =
        this.datas && this.datas.length
          ? this.datas[this.datas.length - 1].staffId
          : 0;
      AdminApi.listStaff(lastId).then((res) => {
        if (!res.data.length) {
          this.isFinished = true;
        } else {
          this.datas = this.datas.concat(res.data);
        }
        this.$refs.scrollView.finishLoadMore();
      });
    },
  },
};
</script>

<style scoped>
.scroll-view {
  height: calc(100vh - 100px);
}
.right-nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
  margin-right: 30px;
}
.staff-info {
  line-height: 36px;
}
.staff-name {
  font-size: 36px;
  margin-bottom: 20px;
}
</style>
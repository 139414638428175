<template>
  <div class="nav-drop-menu">
    <div class="nav-btn" @click="show">
      <svg-icon v-if="menuBtn.isSvg" :name="menuBtn.icon" :size="menuBtn.iconSize"/>
      <md-icon v-else :name="menuBtn.icon" size="lg" />
    </div>
    <div class="menu-box-anchor" v-show="isShow">
      <div class="menu-mask" @click="hide"></div>
      <div class="menu-box">
        <div class="menu-box-arrow"></div>
        <div class="menu-item-wrapper">
          <div class="menu-item" v-for="item in menuItems" :key="item.title" @click="item.clickEvent">
            <div>
              <svg-icon v-if="item.isSvg && item.icon" :name="item.icon" :size="item.iconSize" class="menu-item-icon" />
              <md-icon v-if="!item.isSvg && item.icon" :name="item.icon" size="lg" class="menu-item-icon" />
            </div>
            <label>{{item.title}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nav-drop-menu',
  props: {
    menuBtn: {
      type: Object,
      default() {
        return {
            icon: 'add-user',
            isSvg: true,
            iconSize: '0.38rem'
          }
      }
    },
    menuItems: {
      type: Array,
      default() {
        return [
          {
            title: '示例条目1',
            icon: 'authentication',
            isSvg: false,
            iconSize: '0.38rem',
            clickEvent: function() {}
          },
          {
            title: '示例条目2',
            icon: 'add-user-black',
            isSvg: true,
            iconSize: '0.42rem',
            clickEvent: function() {}
          }
        ]
      } 
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    show() {
      this.isShow = true
    },
    hide() {
      this.isShow = false
    }
  }
}
</script>

<style scoped>
  .nav-drop-menu, .nav-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .menu-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .menu-box-anchor {
    position: relative;
  }
  .menu-box {
    position: absolute;
    width: max-content; 
    background: #ffffff; 
    top: 20px;
    right: -30px;
    border-radius: 10px;
    padding: 20px 30px;
    color: #000;
  }
  .menu-box-arrow {
    border: 10px solid;
    border-color: transparent transparent #ffffff;
    width: 0;
    height: 0;
    position: absolute;
    right: 24px;
    top: -20px;
  }
  .menu-item {
    border-bottom: 1px solid #e2e2e2;
    display: flex;
    align-items: center;
  }
  .menu-item label {
    align-self: flex-end;
    font-size: 32px;
  }
  .menu-item:last-child {
    border-bottom: 0;
  }
  .menu-item-icon {
    margin-right: 10px;
  }
</style>